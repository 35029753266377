<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.seq">
    <div class="detailTitle clearfix">
      <span class="detailMate">{{list.seq}}</span>
      <div class="detailTitlefr">
        <span class="detailCate">开放式</span>
        <span class="detailScore">[{{list.required==0?'选填':'必填'}}]</span>
        {{list.name}}<img v-if="list.img" :src="list.img" @click="checkImg(list.img)"/>
      </div>
    </div>
    <div class="itemDetail">
      <div class="detailOption">
        <div class="optionWrap">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="list.radio"
            class="mb20"
            show-word-limit
          ></el-input>
        </div>
<!--        <div class="optionWrap1">-->
<!--          <span class="fontSize14 lineH26">我的作答</span>-->
<!--          <p class="lineH32 fontSize16">-->
<!--            雄关漫道真如铁，而今迈步从头越。党的十八大开启了中国改革事业的新征程。十八大鲜明提出：必须以更大的政治勇气和智慧，不失时机深化重要领域的改革。习近平总书记多次强调：“改革开放只有进行时，没有完成时，要协调推进各领域各环节的改革，努力把改革开放推向前进。”-->
<!--          </p>-->
<!--        </div>-->
      </div>
    </div>
    <imgDialog ref="ImgDialog"></imgDialog>
  </div>
</template>
<script>
  import imgDialog from '@p/user/components/com/imgDialog.vue'
export default {
  components:{imgDialog},
  props: {
    list: Object
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    checkImg(data) {
      this.$refs.ImgDialog.openInit(data);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/questionnaire";
</style>
