<template>
  <!-- 多选 -->
  <div class="checkWrap" :id="'question' + list.seq">
    <div class="detailTitle clearfix">
      <span class="detailMate">{{list.seq}}</span>
      <div class="detailTitlefr">
        <span class="detailCate">多选题</span>
        <span class="detailScore">[{{list.required==0?'选填':'必填'}}]</span>
        {{ list.name }}<img v-if="list.img" :src="list.img" @click="checkImg(list.img)"/>
      </div>
    </div>
    <div class="itemDetail">
      <div class="detailOption">
        <el-checkbox-group v-model="list.radio" @change="changes">
          <el-checkbox
            :label="item.option_mark"
            v-for="(item, index) in list.children"
            :key="index"
          >
            <i class="optionIcon">{{ item.option_mark }}</i>
            <div class="optionContent">{{item.option_content}}<img v-if="item.option_img" :src="item.option_img" @click="checkImg(item.option_img)"/></div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
<!--      <div class="optionContentdiv1 lineH40 fontSize16">-->
<!--        我的作答：<span class="on">未作答</span>-->
<!--      </div>-->
    </div>
    <imgDialog ref="ImgDialog"></imgDialog>
  </div>
</template>

<script>
  import imgDialog from '@p/user/components/com/imgDialog.vue'
export default {
  components:{imgDialog},
  props: {
    list: Object
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    checkImg(data) {
      this.$refs.ImgDialog.openInit(data);
    },
    changes(){
      if(this.list.opt_num_max){
        if(this.list.radio.length){
          if(this.list.radio.length>this.list.opt_num_max){
            this.$message.error('此题选项最多不得超过'+this.list.opt_num_max+'个')
          }
        }
      }

    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/questionnaire";
.optionIcon{
  border-radius: 6px!important;
}
</style>
