<template>
  <!-- 问卷作答 -->
  <div class="questionsWrap1">
    <header>
      <div class="headerWrap">
        <div class="headerTitle">
          <i class="el-icon-arrow-left" @click="goReturn()"></i>
          <em v-if="questionData.name.length<68">{{ questionData.name }}</em>
          <el-popover v-else
                  placement="bottom"
                  width="680"
                  trigger="hover"
                  :content="questionData.name">
            <em slot="reference">{{questionData.name.substr(0,68)}}…</em>
          </el-popover>


        </div>
        <a class="headerLoad cursorP fontSize14 lineH32" @click="submit()">
          提交
        </a>
      </div>
    </header>
    <main>
      <div class="mainWrap" id="mainWrap">
        <div v-for="(item, index) in question_infos" :key="index">
          <!-- 单选 -->
          <Radio
            class="itemWrap"
            v-if="item.question_type == 1"
            :list="item"
          ></Radio>
          <!-- 多选 -->
          <Checkbox
            class="itemWrap"
            v-if="item.question_type == 2"
            :list="item"
          ></Checkbox>
          <!-- 开放式 -->
          <Discuss
            class="itemWrap"
            v-if="item.question_type == 3"
            :list="item"
          ></Discuss>
          <!-- 数值型-->
          <Number v-if="item.question_type == 4" :list="item" />
        </div>
      </div>
    </main>
    <QuestionnaireDiaLog :type="type" ref="QuestionnaireDiaLog" :typedec="typedec"/>
  </div>
</template>

<script>
import { info, addrespondence } from "./assets/api";
import Radio from "./components/Radio"; // 单选
import Checkbox from "./components/Checkbox"; // 多选
import Discuss from "./components/Discuss"; // 论述
import Number from "./components/Number"; //数值型
import QuestionnaireDiaLog from "./components/QuestionnaireDiaLog";
export default {
  name: "course_questionnaire",
  components: {
    Radio,
    Checkbox,
    Discuss,
    Number,
    QuestionnaireDiaLog,
  },
  data() {
    return {
      question_infos: [],
      questionData: {},
      num: "",
      seconds: 0,
      survey_id: "",
      course_id: "",
      answer_id: "",
      lesson_id:'',
      time: 0,
      timer: "",
      type:0,
      typedec:'',
      survey:{}
    };
  },
  mounted() {
    if (
      this.$route.query.survey_id != undefined &&
      this.$route.query.source_id != ""
    ) {
      this.survey_id = this.$route.query.survey_id;
    }
    if (
      this.$route.query.course_id != undefined &&
      this.$route.query.course_id != ""
    ) {
      this.course_id = this.$route.query.course_id;
    }
    if (
      this.$route.query.answer_id != undefined &&
      this.$route.query.answer_id != ""
    ) {
      this.answer_id = this.$route.query.answer_id;
    }
    if (
            this.$route.query.lesson_id != undefined &&
            this.$route.query.lesson_id != ""
    ) {
      this.lesson_id = this.$route.query.lesson_id;
    }
    this.info();
  },
  methods: {
    goReturn(){
      this.$router.go(-1)
    },
    //跳转计时器
    JsTimer() {
      this.time++;
    },
    //数据处理
    dataProcessing(arr) {
      arr.map((item) => {
        if (item.question_type == 1 || item.question_type == 3) {
          this.$set(item, "radio", "");
          if (item.question_type == 1) {
            this.$set(item, "radiocontent", "");
          }
        } else if (item.question_type == 2) {
          this.$set(item, "radio", []);
        } else if (item.question_type == 4) {
          this.$set(item, "radio", item.default_val);
        }
      });
      this.question_infos = arr;
    },
    info() {
      let params = {
        survey_id: this.survey_id,
        course_id: this.course_id,
        answer_id: this.answer_id,
        lesson_id: this.lesson_id
      };
      if(params.answer_id==0){
        params.answer_id=""
      }
      info(params).then((res) => {
        if (res.code == 0) {
          this.dataProcessing(res.data.questions);
          this.questionData = res.data.survey;
          this.survey=res.data.survey;
          this.timer = setInterval(this.JsTimer, 1000);
        }
      });
    },
    //交卷
    submit() {
      let params = {
        course_id: this.$route.query.course_id, //课程id
        survey_id: this.$route.query.survey_id, //问卷id
        lesson_id: this.$route.query.lesson_id,//课件id
        answer_json: [], //作答结果
        answer_time: this.time, //作答时长
      };
      let arr = [];
      let flag=true;
      let index=[];
      this.question_infos.forEach((item) => {
        var obj = {};
        if (item.question_type == 1) {
          if (item.required == 1) {
            if (item.radio.length == 0) {
              flag=false
            } else {
              let arr1 = [];
              item.children.map((it) => {
                if (item.radio == it.option_mark) {
                  if (it.is_input == 1) {
                    arr1.push({
                      option_id: it.id,
                      content: item.radiocontent,
                    });
                  } else {
                    arr1.push({
                      option_id: it.id,
                      content: it.option_content,
                    });
                  }
                }
              });
                obj = {
                  question_id: item.id,
                  question_type: item.question_type,
                  content: arr1,
                };
                arr.push(obj);
            }
          } else {
            let arr1 = [];
            item.children.forEach((it) => {
              if (item.radio == it.option_mark) {
                if (it.is_input == 1) {
                  arr1.push({
                    option_id: it.id,
                    content: item.radiocontent,
                  });
                } else {
                  arr1.push({
                    option_id: it.id,
                    content: it.option_content,
                  });
                }
              }
            });
              obj = {
                question_id: item.id,
                question_type: item.question_type,
                content: arr1,
              };
              arr.push(obj);
          }
        }
        if (item.question_type == 2) {
          if (item.required == 1) {
            if (item.radio.length < 2) {
              flag=false
            } else {
              let arr1 = [];
              item.radio.map((item1) => {
                item.children.map((it) => {
                  if (item1 == it.option_mark) {
                    arr1.push({
                      option_id: it.id,
                      content: it.option_content,
                    });
                  }
                });
              });
                obj = {
                  question_id: item.id,
                  question_type: item.question_type,
                  content: arr1,
                };
                arr.push(obj);
            }
          } else {
            let arr1 = [];
            item.radio.forEach((item1) => {
              item.children.forEach((it) => {
                if (item1 == it.option_mark) {
                  arr1.push({
                    option_id: it.id,
                    content: it.option_content,
                  });
                }
              });
            });
              obj = {
                question_id: item.id,
                question_type: item.question_type,
                content: arr1,
              };
              arr.push(obj);

          }
        }
        if (item.question_type == 3) {
          if (item.required == 1) {
            if (item.radio == "") {
              flag=false
            } else {
              obj = {
                question_id: item.id,
                question_type: item.question_type,
                content:[{ content: item.radio }],
              };
              arr.push(obj);
            }
          } else {
            obj = {
              question_id: item.id,
              question_type: item.question_type,
              content: [{ content: item.radio }],
            };
            arr.push(obj);
          }
        }
        if (item.question_type == 4) {
          if (item.required == 1) {
            if (item.radio == "") {
              flag=false
            } else {
              obj = {
                question_id: item.id,
                question_type: item.question_type,
                content: [{ content: item.radio.toString() }],
              };
              arr.push(obj);
            }
          } else {
            obj = {
              question_id: item.id,
              question_type: item.question_type,
              content:  [{ content: item.radio.toString() }],
            };
            arr.push(obj);
          }
        }
        if((item.required == 1&&item.radio.length == 0&&item.question_type == 1)||((item.question_type == 3||item.question_type == 4)&&item.required == 1&&item.radio=='')||(item.required == 1&&item.radio.length <2&&item.question_type == 2)){
          index.push(item)
        }
      });
      if(index.length!=0){
        if(index[0].question_type == 2){
          this.type=1;
          this.typedec='多选题至少两项';
          this.$refs.QuestionnaireDiaLog.QuestionnaireDiaLogShow=true;
        }else {
          this.type=1;
          this.typedec='请填写必填项题目！';
          this.$refs.QuestionnaireDiaLog.QuestionnaireDiaLogShow=true;
        }
        let t = document.querySelector("#question" + index[0].seq).offsetTop;
        window.scrollTo(0, t - 154);
      }
      params.answer_json = JSON.stringify(arr);
      if(flag){
        addrespondence(params).then(res=>{
          if(res.code==0){
            this.type=2;
            this.typedec=this.survey.prompt;
            this.$refs.QuestionnaireDiaLog.QuestionnaireDiaLogShow=true;
            this.$nextTick(()=>{
              this.$router.push({
                path: "/questionnaire_report",
                query: {
                  survey_id: this.survey_id,
                  course_id: this.course_id,
                  answer_id: res.data.answer_id,
                  lesson_id: this.lesson_id,
                },
              });
            })
          }
        })
      }
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
};
</script>

<style scoped lang="scss">
@import "./assets/questionnaire";

</style>
