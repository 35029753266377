<template>
  <div class="QuestionnaireDiaLog" v-show="QuestionnaireDiaLogShow">
    <div class="QuestionnaireDiaLogdiv">
      <div class="QuestionnaireDiaLogdivtit">
        <i
          class="iconfont"
          :class="
            type == 1
              ? 'icon-advice_submit_fail1'
              : type == 2
              ? 'icon-icon_judge_empty success'
              : ''
          "
        ></i>
      </div>
      <h5 class="QuestionnaireDiaLogdivh5 fontSize18 lineH24" v-if="type == 2">
        提交成功
      </h5>
      <p class="QuestionnaireDiaLogdivp fontSize16 lineH26">
        {{typedec}}
      </p>
      <span
        class="QuestionnaireDiaLogdivsp fontSize16"
        @click="QuestionnaireDiaLogsubmit"
        >确定</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireDiaLog",
  props:{
    type:Number,
    typedec:String
  },
  data() {
    return {
      QuestionnaireDiaLogShow: false,
    };
  },
  methods: {
    QuestionnaireDiaLogsubmit() {
      this.QuestionnaireDiaLogShow = false;
      if(this.type == 2){
        let ids = this.$route.query.course_id
        this.$router.push({
          path:'/learn',
          query:{course_id:ids}
        })
      }

    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/QuestionnaireDiaLog";
</style>
